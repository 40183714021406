import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import facebook from '../../img/social/facebook.svg';
import instagram from '../../img/social/instagram.svg';
import twitter from '../../img/social/twitter.svg';
import linkedin from '../../img/social/linkedin.svg';
import youtube from '../../img/social/youtube.svg';
import facebookWhite from '../../img/social/facebook-white.svg';
import instagramWhite from '../../img/social/instagram-white.svg';
import twitterWhite from '../../img/social/twitter-white.svg';
import linkedinWhite from '../../img/social/linkedin-white.svg';
import youtubeWhite from '../../img/social/youtube-white.svg';

const SocialContainer = styled('div')(({ theme, disablePadding }) => ({
  paddingTop: disablePadding ? 'none' : theme.spacing(3),
}));

const SocialLink = styled('a')(({ theme }) => ({
  paddingRight: theme.spacing(4),
}));

const SocialLogo = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: 32,
    width: 32,
  },
  height: 24,
  width: 24,
}));

export function SocialButton({
  platform, href, isWhite,
}) {
  let icon;
  if (platform === 'twitter') {
    icon = isWhite ? twitterWhite : twitter;
  } else if (platform === 'linkedin') {
    icon = isWhite ? linkedinWhite : linkedin;
  } else if (platform === 'instagram') {
    icon = isWhite ? instagramWhite : instagram;
  } else if (platform === 'facebook') {
    icon = isWhite ? facebookWhite : facebook;
  } else if (platform === 'youtube') {
    icon = isWhite ? youtubeWhite : youtube;
  }
  return (
    <SocialLink
      title={platform}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <SocialLogo
        src={icon}
        alt={platform}
      />
    </SocialLink>
  );
}

SocialButton.propTypes = {
  platform: PropTypes.string,
  href: PropTypes.string,
  isWhite: PropTypes.bool,
};

function Social({ mobile, disablePadding }) {
  return (
    <SocialContainer
      disablePadding={disablePadding}
    >
      <SocialButton
        platform="twitter"
        href="https://twitter.com/nualangapp"
        isWhite={mobile}
      />
      <SocialButton
        platform="linkedin"
        href="https://www.linkedin.com/company/nualang/"
        isWhite={mobile}
      />
      <SocialButton
        platform="instagram"
        href="https://instagram.com/nualangapp"
        isWhite={mobile}
      />
      <SocialButton
        platform="facebook"
        href="https://www.facebook.com/nualangapp"
        isWhite={mobile}
      />
      <SocialButton
        platform="youtube"
        href="https://www.youtube.com/channel/UCVApUy9Pp7bKp8nC74Znstg"
        isWhite={mobile}
      />
    </SocialContainer>
  );
}

Social.propTypes = {
  mobile: PropTypes.bool,
  disablePadding: PropTypes.bool,
};

export default Social;
