import React from 'react';
import Notes from '@mui/icons-material/Notes';

export default {
  topNav: [
    {
      name: 'Blog',
      url: '/blog/',
      icon: <Notes />,
    },
    // {
    //   name: 'Docs',
    //   url: 'https://api.nualang.fathominnovation.com',
    //   icon: <GroupWork />
    // }
    // {
    //   divider: true
    // },
  ],
  website: {
    topNav: [
      {
        name: 'resources',
        links: [
          {
            name: 'Educators',
            url: '/educators',
          },
          {
            name: 'Features',
            url: '/features',
          },
          {
            name: 'guides',
            url: '/guides',
          },
          {
            name: 'faqs',
            url: '/faq',
          },
          {
            name: 'blog',
            url: '/blog',
          },
          {
            name: 'team',
            url: '/team',
          },
          {
            name: 'Pricing',
            url: '/pricing',
          },
          {
            name: 'Get a Demo',
            url: '/demo',
          },
          {
            name: 'Free Trial',
            url: '/trial',
          },
        ],
      },
      {
        name: 'contact',
        url: '/contact',
      },
    ],
  },
};
