import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  AppBar as MuiAppBar, Toolbar, Button, IconButton, Collapse, Divider as MuiDivider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import SiteLanguageSelector from '@fathomtech/nualang-ui-components/dist/Misc/SiteLanguageSelector';
import Social from '../Social/Social';
import i18n from '../../../i18n';
import config from '../../config';
import navigation from '../../_nav';

const { website: { topNav } } = navigation;

const Root = styled('div')(({ theme, isFullscreenNavOpen }) => ({
  [theme.breakpoints.down('md')]: {
    display: isFullscreenNavOpen ? 'block' : 'none',
    position: 'fixed',
    zIndex: 2,
    background: 'linear-gradient(90deg, #AD3DAA 1.6%, #8400A1 86.27%)',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflowY: 'auto',
  },
  display: 'none',
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  boxShadow: 'none',
  '& .MuiToolbar-gutters': {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
}));

const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginTop: theme.spacing(4),
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  margin: theme.spacing(),
}));

const Divider = styled(MuiDivider)(() => ({
  backgroundColor: '#fff',
}));

const SocialContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
}));

const LanguageSelectorContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: '#fff',
}));

const SubMenuLinksContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

function SubMenu({ name, links }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <NavButton
        color={'inherit'}
        onClick={() => setExpanded(!expanded)}
      >
        {t(name)} {expanded
          ? <ExpandLessIcon
            sx={(theme) => ({
              marginLeft: theme.spacing(),
            })}
          />
          : <ExpandMoreIcon
            sx={(theme) => ({
              marginLeft: theme.spacing(),
            })}
          />
        }
      </NavButton>
      <Collapse in={expanded}>
        <Divider />
        <SubMenuLinksContainer>
          {
            links.map((linkItem, i) => (
              <NavButton
                key={`mobile-${name}-menu-${i}`}
                color={'inherit'}
                component={Link}
                to={linkItem.url}
              >
                {t(linkItem.name)}
              </NavButton>
            ))
          }
        </SubMenuLinksContainer>
        <Divider />
      </Collapse>
    </>
  );
}

SubMenu.propTypes = {
  name: PropTypes.string,
  links: PropTypes.array,
};

function FullscreenNav({ isFullscreenNavOpen, setIsFullscreenNavOpen }) {
  // const classes = useStyles({ isFullscreenNavOpen });
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Root
      isFullscreenNavOpen={isFullscreenNavOpen}
    >
      <AppBar
        position="static"
        color="transparent"
      >
        <Toolbar>
          <IconButton
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
              color: '#fff',
            })}
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => setIsFullscreenNavOpen(false)}
            size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Toolbar>
          <Nav>
            {
              topNav.map((navItem, i) => {
                if (navItem.links && navItem.links.length > 0) {
                  return (
                    <SubMenu
                      key={`mobile-top-nav-${i}`}
                      {...navItem}
                    />
                  );
                }
                return (
                  <NavButton
                    key={`mobile-top-nav-${i}`}
                    color="inherit"
                    component={Link}
                    to={navItem.url}
                  >
                    {t(navItem.name)}
                  </NavButton>
                );
              })
            }
            <NavButton
              color={'inherit'}
              component="a"
              href={`${config.APP_URL}/`}
            >
              {t('sign_in')}
            </NavButton>
            <NavButton
              color={'inherit'}
              variant={'outlined'}
              component="a"
              href={`${config.APP_URL}/?action=signUp`}
            >
              {t('sign_up')}
            </NavButton>
            <SocialContainer>
              <Social mobile />
            </SocialContainer>
            <LanguageSelectorContainer>
              <SiteLanguageSelector
                t={t}
                color={'inherit'}
                currentLanguage={currentLanguage}
                handleChangeLanguage={handleChangeLanguage}
                languageOptions={[
                  {
                    value: 'en',
                    label: 'English',
                  },
                  {
                    value: 'en-US',
                    label: 'English US',
                  },
                  {
                    value: 'es',
                    label: 'Español',
                  },
                  {
                    value: 'it',
                    label: 'Italiano',
                  }]}
              />
            </LanguageSelectorContainer>
          </Nav>
        </Toolbar>
      </AppBar>
    </Root>
  );
}

FullscreenNav.propTypes = {
  isFullscreenNavOpen: PropTypes.bool,
  setIsFullscreenNavOpen: PropTypes.func,
};

export default FullscreenNav;
