import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Popper as MuiPopper,
  Grow,
  ClickAwayListener,
  MenuList,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SiteLanguageSelector from '@fathomtech/nualang-ui-components/dist/Misc/SiteLanguageSelector';
import { useTranslation } from 'react-i18next';
import logo from '../../../static/img/logo/nualang-logo-primary.svg';
import config from '../../config';
import FullscreenNav from './FullscreenNav';
import i18n from '../../../i18n';
import navigation from '../../_nav';
import Section from '../Section';

const { website: { topNav } } = navigation;

const Root = styled('div')(() => ({
  flexGrow: 1,
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  boxShadow: 'none',
  '& .MuiToolbar-gutters': {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const Brand = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
}));

const BrandLogo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: '32px',
  },
  marginTop: '12px',
  height: '40px',
}));

const NavButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  margin: theme.spacing(1, 1.5),
  color: '#212353',
}));

const Popper = styled(MuiPopper)(() => ({
  zIndex: 1001,
}));

function NavbarDropdown({ name, links }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownAnchorRef = useRef(null);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isDropdownOpen);
  const { t } = useTranslation();

  const handleToggle = () => setIsDropdownOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (dropdownAnchorRef.current && dropdownAnchorRef.current.contains(event.target)) {
      return;
    }

    setIsDropdownOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsDropdownOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && isDropdownOpen === false) {
      dropdownAnchorRef.current.focus();
    }

    prevOpen.current = isDropdownOpen;
  }, [isDropdownOpen]);

  return (
    <>
      <NavButton
        ref={dropdownAnchorRef}
        onClick={handleToggle}
        color="inherit"
      >
        {t(name)} {isDropdownOpen
          ? <ExpandLessIcon
              sx={(theme) => ({
                marginLeft: theme.spacing(),
              })}
            />
          : <ExpandMoreIcon
              sx={(theme) => ({
                marginLeft: theme.spacing(),
              })}
            />
        }
      </NavButton>
      <Popper
        open={isDropdownOpen}
        anchorEl={dropdownAnchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isDropdownOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {
                    links.map((linkItem, i) => (
                      <MenuItem
                        key={`${name}-menu-${i}`}
                        component={Link}
                        to={linkItem.url}
                      >
                        {t(linkItem.name)}
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

NavbarDropdown.propTypes = {
  name: PropTypes.string,
  links: PropTypes.array,
};

function Account() {
  const { t } = useTranslation();

  return (
    <>
      <NavButton
        color="inherit"
        component="a"
        href={`${config.APP_URL}/`}
      >
        {t('sign_in')}
      </NavButton>
      <Button
        color="primary"
        variant="contained"
        component="a"
        href={`${config.APP_URL}/?action=signUp`}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
          margin: theme.spacing(1, 1.5),
        })}
      >
        {t('sign_up')}
      </Button>
    </>
  );
}

Account.propTypes = {
};

export default function Navbar() {
  const [isFullscreenNavOpen, setIsFullscreenNavOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const currentLanguage = i18n.language;

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Root>
      <Section>
        <AppBar
          position="static"
          color="transparent"
        >
          <Toolbar>
            <Brand>
              <Link to="/">
                <BrandLogo src={logo} alt="Nualang" />
              </Link>
            </Brand>
            <div>
              {
                !isSmallScreen && (
                  <SiteLanguageSelector
                    t={t}
                    currentLanguage={currentLanguage}
                    handleChangeLanguage={handleChangeLanguage}
                    languageOptions={[
                      {
                        value: 'en',
                        label: 'English',
                      },
                      {
                        value: 'en-US',
                        label: 'English US',
                      },
                      {
                        value: 'es',
                        label: 'Español',
                      },
                      {
                        value: 'it',
                        label: 'Italiano',
                      },
                      // {
                      //   value: 'de',
                      //   label: 'Deutsche'
                      // },
                      // {
                      //   value: 'ga',
                      //   label: 'Gaeilge'
                      // },
                      // {
                      //   value: 'pt',
                      //   label: 'Portugues'
                      // },
                      // {
                      //   value: 'fr',
                      //   label: 'Français'
                      // },
                    ]}
                  />
                )
              }
              {
                topNav.map((navItem, i) => {
                  if (navItem.links && navItem.links.length > 0) {
                    return (
                      <NavbarDropdown
                        key={`top-nav-${i}`}
                        {...navItem}
                      />
                    );
                  }
                  return (
                    <NavButton
                      key={`top-nav-${i}`}
                      color="inherit"
                      component={Link}
                      to={navItem.url}
                    >
                      {t(navItem.name)}
                    </NavButton>
                  );
                })
              }
            </div>
            <Account />
            <IconButton
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
              })}
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsFullscreenNavOpen(true)}
              size="large">
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Section>
      <FullscreenNav
        isFullscreenNavOpen={isFullscreenNavOpen}
        setIsFullscreenNavOpen={setIsFullscreenNavOpen}
      />
    </Root>
  );
}
