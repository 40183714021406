/* eslint-disable camelcase */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// translations
import {
  en, en_US, it, ga, pt, de, es, fr,
} from './translations';

const resources = {
  en: {
    translation: en,
  },
  'en-US': {
    translation: en_US,
  },
  it: {
    translation: it,
  },
  ga: {
    translation: ga,
  },
  fr: {
    translation: fr,
  },
  pt: {
    translation: pt,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
