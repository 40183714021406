import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const SectionContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.only('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '896px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '1216px',
  },
  flexGrow: 1,
  margin: '0 auto',
  position: 'relative',
  width: 'auto',
}));

function Section({ children, sx, className }) {
  return (
    <SectionContainer sx={sx} className={className || ''}>
      {children}
    </SectionContainer>
  );
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sx: PropTypes.func,
};

export default Section;
