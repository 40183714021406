import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import { DateTime } from 'luxon';
import useSiteMetadata from '../utils/SiteMetadata';
// import CookieFooter from '../components/CookieFooter/CookieFooter';
import Offer from '../components/Offer/Offer';
import config from '../config';

const TemplateWrapper = ({ children, authData }) => {
  const { title, description } = useSiteMetadata();
  const [isOfferOpen, setIsOfferOpen] = useState(false);

  useEffect(() => {
    const isTeacher = !!(authData && authData.attributes
        && authData.attributes['custom:role'] === 'teach');
    if (isTeacher) {
      setIsOfferOpen(true);
    }
  }, [authData]); // Only re-run the effect if the authData changes

  // const DisplayCookieFooter = () => {
  //   if (typeof window !== 'undefined' && window) {
  //     const acceptedCP = localStorage.getItem('acceptedCP');
  //     const navigator = () => {
  //       // navigate to new page with cookie policy
  //       console.log('navigator clicked');
  //     };
  //     const space = '  ';
  //     const acceptCookie = async () => {
  //       try {
  //         localStorage.setItem('acceptedCP', true);
  //       } catch (error) {
  //         console.error('error accepting cookie', error);
  //       }
  //     };
  //     const rejectCookie = () => {
  //       console.log('Accepted Cookies');
  //     };
  //     if (!acceptedCP) {
  //       return (
  //         <CookieFooter
  //           url={'/cookies'}
  //           navigator={navigator}
  //           acceptCookie={acceptCookie}
  //           rejectCookie={rejectCookie}
  //         >
  //           {`This website uses only essential cookies in order to provide you with the best possible experience.
  //           You can find all relevant information by reading our ${space} `}
  //         </CookieFooter>
  //       );
  //     }
  //   }
  //   return '';
  // };

  const DisplayOffer = () => {
    if (typeof window !== 'undefined' && window) {
      const currentMoment = DateTime.now().toUTC();
      const notInterestedInOffer = localStorage.getItem(`notInterestedInOffer-${currentMoment.toFormat('dd/MM/yyyy')}`);
      const payNow = () => {
        localStorage.setItem(`notInterestedInOffer-${currentMoment.toFormat('dd/MM/yyyy')}`, true);
        // setIsOfferOpen(false);
      };
      const notNow = () => {
        localStorage.setItem(`notInterestedInOffer-${currentMoment.toFormat('dd/MM/yyyy')}`, true);
        setIsOfferOpen(false);
      };
      const dateToCheck = '2021-06-01';
      const isOfferStillValid = currentMoment < dateToCheck;
      if (!notInterestedInOffer && isOfferStillValid) {
        return (
          <Offer
            isOpen={isOfferOpen}
            payNowUrl={'https://shop.nualang.com/56640569512/checkouts/8d6bbb9d1331d66c166f9bcca8dac398'}
            invoiceUrl={'https://share.hsforms.com/16nHBbiS_REKv4wGgk0fiKw5frtv'}
            payNow={payNow}
            notNow={notNow}
            heading={'Exclusive Offer valid until May 31st! 75% off Nualang for the 2021/2022 school year'}
            description={`We want to recognise and reward all of those using Nualang to help teach their students the languages they love. 
            That is why we are offering 75% off to all Nualang educators for the 2021/2022 academic year! 
            Instead of paying €40 per month you will pay only €10 per month per teacher. Students accounts will remain free!`}
          />
        );
      }
    }
    return '';
  };

  const ogImage = `${config.SITE_URL}/img/og-image.png`;

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#8300A1"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${ogImage}`}
        />
        <meta
          property="twitter:image"
          content={`${ogImage}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
        <div>{children}</div>
        {/* {DisplayCookieFooter()} */}
        {DisplayOffer()}
    </div>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.node,
  authData: PropTypes.object,
};

export default TemplateWrapper;
